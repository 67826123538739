import React from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import actAddCountry from "../../../../../store/countries/act/actAddCountry";

function AddCountryForm() {
  const { loading } = useSelector((state) => state.countriesSlice);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    // validationSchema: UserSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values) => {
      dispatch(actAddCountry(values))
        .unwrap()
        .then(() => navigate("/dashboard/countries"))
        .catch((error) => {
          console.log(error);
        });
    },
  });

  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title fw-semibold mb-4">اضافه وجهة جديده</h5>
        <Form onSubmit={formik.handleSubmit} className="py-2">
          <div className="row mb-3">
            <div className="col-lg-10">
              <input
                className="form-control"
                type="text"
                name="name"
                value={formik.values.name}
                placeholder="أضف وجهة جديدة"
                onChange={formik.handleChange}
                autoComplete="off"
              />
            </div>
            <div className="col-lg-2">
              <button
                className="btn btn-warning w-100"
                type="submit"
                disabled={loading || !(formik.isValid && formik.dirty)}
              >
                {loading ? (
                  <span className="spinner-border spinner-border-sm" />
                ) : (
                  <>اضف</>
                )}
              </button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default AddCountryForm;
