import { Button } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
// import { useSelector } from "react-redux";
// import { RootState } from "../../../store";

import UsersTable from "../../component/tables/UsersTable";
import Breadcrumb from "../../../../../components/common/breadcrumb/Breadcrumb";
import withGuard from "../../../../../utils/withGurard";

const UsersListPage = () => {
  //   const {message} = useSelector((state: RootState) => state.userManagagement)
  const navigate = useNavigate();

  return (
    <>
      <Breadcrumb
        breadcrumbHeading="ادارة المستخدمين"
        dashboard="لوحة التحكم"
        breadcrumb="جميع المستخدمين"
      >
        <Button
          className="btn btn-sm btn-primary pe-2"
          onClick={() => navigate("/dashboard/users/add")}
        >
          أضف مستخدم
        </Button>
      </Breadcrumb>
      <div className="card card-custom shadow">
        <div className="card-header card-header-stretch">
          {/* {message && <MessageNotification message={message} />} */}
          <UsersTable />
        </div>
      </div>
    </>
  );
};

export default withGuard(UsersListPage);
