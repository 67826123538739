import { configureStore } from "@reduxjs/toolkit";
import logIn from "./auth/logIn";
//import notificationSlice from "./notification/notificationSlice";
import toursSlice from "./tours/toursSlice";
import bookingSlice from "./booking/bookingSlice";
import usersSlice from "./user-management/userManagementSlice";
import countriesSlice from "./countries/countriesSlice";

const store = configureStore({
  reducer: {
    logIn,
    toursSlice,
    bookingSlice,
    usersSlice,
    countriesSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
