import React from "react";
import { Link } from "react-router-dom";

const CountryCard = ({ id, name, route }) => {
  return (
    <div key={id} className="col-xxl-3 col-md-6">
      <Link to={`/dashboard/${route}/${id}`}>
        <div className="card border-top border-warning cursor-pointer">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-center">
              <div>
                <h4 className="card-title fs-6 text-center">{name}</h4>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default CountryCard;
