// import {Dropdown} from 'react-bootstrap'
import { useMemo } from "react";
import { Link } from "react-router-dom";

// function to define table columns
const defineColumns = () => [
  {
    name: "اسم الوجهة",
    selector: (row) => row.name,
    sortable: true,
    cell: (d) => (d.name ? d?.name : "--"),
    cellExport: (row) => row.name,
  },
  {
    name: "الاجراءات",
    selector: (row) => row?.id,
    sortable: true,
    cell: (d) => (
      <Link
        className="btn btn-primary"
        to={`/dashboard/countries/edit/${d.id}`}
      >
        تعديل
      </Link>
    ),
  },
];

const useCountriesTable = () => {
  const columns = useMemo(() => defineColumns(), []);
  return { columns };
};

export default useCountriesTable;
