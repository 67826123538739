/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { NavLink } from "react-router-dom";

const Breadcrumb = ({
  breadcrumbHeading,
  dashboard,
  breadcrumb1,
  breadcrumbNavLink1,
  breadcrumb2,
  breadcrumbNavLink2,
  breadcrumb3,
  breadcrumbNavLink3,
  breadcrumb,
  children,
}) => {
  return (
    <div className=" shadow-none position-relative overflow-hidden ">
      <div className="card-body  py-3">
        <div className="row align-items-center">
          <div className="col-9">
            <h4 className="page-heading">{breadcrumbHeading}</h4>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <NavLink className="text-muted" to="/dashboard">
                    {dashboard}
                  </NavLink>
                </li>
                {breadcrumb1 && breadcrumbNavLink1 && (
                  <li className="breadcrumb-item">
                    <NavLink className="text-muted " to={breadcrumbNavLink1}>
                      {breadcrumb1}
                    </NavLink>
                  </li>
                )}
                {breadcrumb2 && breadcrumbNavLink2 && (
                  <li className="breadcrumb-item">
                    <NavLink className="text-muted " to={breadcrumbNavLink2}>
                      {breadcrumb2}
                    </NavLink>
                  </li>
                )}
                {breadcrumb3 && breadcrumbNavLink3 && (
                  <li className="breadcrumb-item">
                    <NavLink className="text-muted " to={breadcrumbNavLink3}>
                      {breadcrumb3}
                    </NavLink>
                  </li>
                )}
                <li className="breadcrumb-item fw-bold" aria-current="page">
                  {breadcrumb}
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-3">
            <div className="text-start ">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Breadcrumb;
