import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const addUser = createAsyncThunk(
  "userManagement/addUser",
  async (newUser, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    const token = localStorage.getItem("token");

    try {
      const { data } = await axios({
        method: "POST",
        url: `users/register`,
        data: newUser,
        headers: { Authorization: `Bearer ${token}` },
      });

      //   if (data?.user && data?.token) {
      //     dispatch(setMessage(`تم اضافة المستخدم ${data.user.username} بنجاح`));
      //   }

      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export default addUser;
