import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const actAddCountry = createAsyncThunk(
  "countriesSlice/actAddCountry",
  async (country, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const { data } = await axios({
        method: "POST",
        url: "countries/store",
        data: country,
      });

      //  if (data?.status === 200) {
      //    dispatch(setMessage(`تم اضافه  بنجاح`))
      //  }

      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export default actAddCountry;
