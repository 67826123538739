import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { resetCountry } from "../../../../../store/countries/countriesSlice";
import actFetchSingleCountry from "../../../../../store/countries/act/actFetchSingleCountry";
import { useParams } from "react-router-dom";

const useCountryDetails = () => {
  //state
  const { loading, country } = useSelector((state) => state.countriesSlice);
  //helper hooks
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(actFetchSingleCountry(id));

    //reset country details when the user leaves the page
    return () => {
      dispatch(resetCountry());
    };
  }, [dispatch, id]);

  return { country, loading };
};

export default useCountryDetails;
