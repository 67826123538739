import useCountriesTable from "../hooks/useTable";
import Table from "../../../../../components/common/table_helpers/table";
import useCountriesData from "../hooks/useData";

const CountriesTable = () => {
  //custom hooks for countries table
  const { data, loading } = useCountriesData();
  const { columns } = useCountriesTable();

  return <Table data={data} columns={columns} loading={loading} />;
};

export default CountriesTable;
