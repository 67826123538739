import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";


const editUser = createAsyncThunk(
  "userManagement/editUser",
  async (editUserData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
     const token = localStorage.getItem("token");
    try {
      const { data } = await axios({
        method: "post",
        url: `users/edit/${editUserData.id}`,
        data: editUserData,
        headers: { Authorization: `Bearer ${token}` },
      });
      // if (data?.status === 200) {
      //   dispatch(setMessage("تم تعديل المستخدم بنجاح"));
      // }
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export default editUser;
