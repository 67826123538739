import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const addBooking = createAsyncThunk(
  "booking/addBooking",
  async (newBooking, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    const token = localStorage.getItem('token')

    try {
      const { data } = await axios({
        method: "POST",
        url: "booking/store",
        data: newBooking,
        headers: {Authorization: `Bearer ${token}`},
      });

      //  if (data?.status === 200) {
      //    dispatch(setMessage(`تم اضافه  بنجاح`))
      //  }

      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export default addBooking;
