import React from "react";
import { Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import addTours from "../../../../../store/tours/act/addTours";
import useCountriesData from "../../../countries/components/hooks/useData";
function AddForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { country } = useParams();
  const { data } = useCountriesData();

  const formik = useFormik({
    initialValues: {
      title: "",
      start_date: "",
      end_date: "",
      company: "",
      type: "",
      rooms_count: "",
      country_id: country,
      flight_seats_count: "",
    },
    // validationSchema: UserSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values) => {
      dispatch(addTours({ ...values, country_id: Number(values.country_id) }))
        .unwrap()
        .then(() => navigate("/dashboard/tour"))
        .catch((error) => {
          console.log(error);
        });
    },
  });

  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title fw-semibold mb-4">اضافه رحله جديده</h5>
        <Form onSubmit={formik.handleSubmit} className="py-2">
          <div className="row mb-3">
            <div className="col-lg-4">
              <label>اسم الرحله</label>
              <input
                className="form-control"
                type="text"
                name="title"
                value={formik.values.title}
                onChange={formik.handleChange}
              />
            </div>
            <div className="col-lg-4">
              <label>تاريخ البدايه</label>
              <input
                className="form-control"
                type="date"
                name="start_date"
                value={formik.values.start_date}
                onChange={formik.handleChange}
              />
            </div>
            <div className="col-lg-4">
              <label>تاريخ النهايه</label>
              <input
                className="form-control"
                type="date"
                name="end_date"
                value={formik.values.end_date}
                onChange={formik.handleChange}
              />
            </div>
            <div className="col-lg-4 mt-5">
              <label>الوجهة</label>
              <select
                className="form-control"
                name="country_id"
                onChange={formik.handleChange}
                value={formik.values.country_id}
                aria-label="Select example"
                disabled
              >
                <option value="" disabled>
                  اختر الوجهة
                </option>
                {data &&
                  data.length > 0 &&
                  data.map((el) => (
                    <option value={el.id} key={el.id}>
                      {el.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-lg-4 mt-5">
              <label> نوع الرحله</label>
              <select
                className="form-control"
                name="type"
                onChange={formik.handleChange}
                value={formik.values.type}
                aria-label="Select example"
              >
                <option value="" disabled>
                  {" "}
                  اختر نوع الرحله
                </option>
                <option value="private">جولات خاصه </option>
                <option value="public"> جولات عامه</option>
                <option value="both"> جولات خاصه و عامه معا</option>
              </select>
            </div>

            <div className="col-lg-4 mt-5">
              <label> الشركه</label>
              <select
                className="form-control"
                name="company"
                value={formik.values.company}
                onChange={formik.handleChange}
                aria-label="Select example"
              >
                <option value="" disabled>
                  {" "}
                  اختر الشركه
                </option>
                <option value="Eqlaa">اقلاع </option>
                <option value="RT">RT </option>
              </select>
            </div>
            <div className="col-lg-6 mt-5">
              <label>عدد الغرف</label>
              <input
                className="form-control"
                type="number"
                name="rooms_count"
                value={formik.values.rooms_count}
                onChange={formik.handleChange}
              />
            </div>
            <div className="col-lg-6 mt-5">
              <label>عدد مقاعد الطيران</label>
              <input
                className="form-control"
                type="number"
                name="flight_seats_count"
                value={formik.values.flight_seats_count}
                onChange={formik.handleChange}
              />
            </div>
          </div>
          <button className="btn btn-warning mt-2 px-5" type="submit">
            اضف
          </button>
        </Form>
      </div>
    </div>
  );
}

export default AddForm;
