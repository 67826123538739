import { Button, Col, Row, Form, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useState } from "react";

import { useFormik } from "formik";
import addBooking from "../../../../../store/booking/act/addBooking";
import fetchAllBooking from "../../../../../store/booking/act/fetchAllBooking";

import * as Yup from "yup"; // Import Yup for schema validation

const AddBookingModal = ({ tourId, type }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const validationSchema = Yup.object().shape({
    type: Yup.string()
      .required()
      .oneOf(["private", "public"], "يجب اختيار نوع الجولة"), // Validation rule for type
    travelers: Yup.number().integer().required(),
    rooms_count: Yup.number().integer().required(),
    flight_seats_count: Yup.number().integer().required(),
  });

  const formik = useFormik({
    initialValues: {
      tour_id: tourId,
      travelers: "",
      type: type,
      rooms_count: "",
      flight_seats_count: "",
    },
    validationSchema,
    onSubmit: (values) => {
      let company = localStorage.getItem("company");
      let firstName = localStorage.getItem("first_name");
      let lastName = localStorage.getItem("last_name");
      let employee_name = `${firstName} ${lastName}`;
      let data = {
        company: company,
        employee_name: employee_name,
        type_public_count: values.type_public_count || 0,
        type_private_count: values.type_private_count || 0,
        flight_seats_count: values.flight_seats_count || 0,
        rooms_count: values.rooms_count || 0,
        travelers: values.travelers || 0,
        type: values.type,
        tour_id: tourId,
      };

      dispatch(addBooking(data))
        .unwrap()
        .then(() => {
          dispatch(fetchAllBooking());
          handleClose();
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
  });

  return (
    <>
      <Button
        className="btn btn-sm pe-2 text-nowrap text-center"
        variant="primary"
        onClick={handleShow}
      >
        اضف حجز
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Form onSubmit={formik.handleSubmit}>
          <Modal.Header className="bg-primary text-white">
            <Modal.Title className="text-white">أضف حجز</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={12}>
                {type === "both" && (
                  <div className="row py-2 mb-2">
                    <div className="col-md-8 col-xl-9">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input warning"
                          type="radio"
                          name="type"
                          onChange={formik.handleChange}
                          value="private"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="warning-radio"
                        >
                          جولات خالصة
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input warning"
                          type="radio"
                          name="type"
                          onChange={formik.handleChange}
                          value="public"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="warning2-radio"
                        >
                          جولات عامة
                        </label>
                      </div>
                    </div>
                    {formik.errors.type && formik.touched.type ? (
                      <div className="text-danger mt-2">
                        {formik.errors.type}
                      </div>
                    ) : null}
                  </div>
                )}
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <div className="mb-10 mt-2">
                  <label className="mb-1">عدد المسافرين</label>
                  <input
                    className="form-control"
                    type="number"
                    name="travelers"
                    value={formik.values.travelers}
                    onChange={formik.handleChange}
                  />
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-10 mt-2">
                  <label className="mb-1">عدد الغرف</label>
                  <input
                    className="form-control"
                    type="number"
                    name="rooms_count"
                    value={formik.values.rooms_count}
                    onChange={formik.handleChange}
                  />
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-10 mt-2">
                  <label className="mb-1">عدد مقاعد الطيران</label>
                  <input
                    className="form-control "
                    type="number"
                    name="flight_seats_count"
                    value={formik.values.flight_seats_count}
                    onChange={formik.handleChange}
                  />
                </div>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-light" onClick={handleClose}>
              الغاء
            </Button>
            <Col sm={4}>
              <button
                type="submit"
                className="btn btn-primary w-100"
                //   disabled={loading || !(formik.isValid && formik.dirty)}
              >
                اضف
              </button>
            </Col>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default AddBookingModal;
