import { createSlice } from "@reduxjs/toolkit";
import fetchAllBooking from "./act/fetchAllBooking";
import addBooking from "./act/addBooking";

const initialState = {
  data: [],
  error: null,
  loading: false,
};

const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // ========================== add booking ==========================
    builder.addCase(fetchAllBooking.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchAllBooking.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchAllBooking.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // ========================== add booking ==========================
    builder.addCase(addBooking.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(addBooking.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(addBooking.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default bookingSlice.reducer;
