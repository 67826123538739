import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const addTours = createAsyncThunk('tour/addTours', async (newTour, thunkAPI) => {
  const {rejectWithValue} = thunkAPI
//  const token = localStorage.getItem('token')

  try {
    const {data} = await axios({
      method: 'POST',
      url: 'tours/store',
      data: newTour,
      // headers: {Authorization: `Bearer ${token}`},
    })

    //  if (data?.status === 200) {
    //    dispatch(setMessage(`تم اضافه  بنجاح`))
    //  }

    return data
  } catch (error) {
    return rejectWithValue(error.response.data.message)
  }
})

export default addTours