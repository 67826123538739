import { createSlice } from "@reduxjs/toolkit";

import authLogin from "./authLogin";

const initialState = { loading: null, error: null };

const logIn = createSlice({
  name: "logIn",
  initialState,
  reducers: {
    closeNotification: (state) => {
      state.message = null;
    },
    setMessage: (state, action) => {
      state.message = action.payload;
    },
    setLogoutMessage: (state, action) => {
      state.Logoutmessage = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authLogin.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(authLogin.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(authLogin.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const {
  resetUser,
  closeNotification,
  setError,
  setLogoutMessage,
  setMessage,
} = logIn.actions;
export default logIn.reducer;
