import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import fetchAllReservations from "../../../../../store/tours/act/fetchAllReservation";
import CancelBookingModal from "../../../booking/components/modal/CancelBookingModal";

const useReservationTable = () => {
  const { data } = useSelector((state) => state.toursSlice);
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    dispatch(fetchAllReservations(id));
  }, [dispatch, id]);

  const customStyles = useMemo(() => {
    return {
      rows: {
        style: {
          minHeight: "110px",
          textAlign: "center",
          borderBottomWidth: "1px",
          borderBottomStyle: "dashed !important",
          borderBottomColor: "#f1f1f4",
        },
      },
      headCells: {
        style: {
          color: "#99a1b7",
          justifyContent: "center",
          fontWeight: "bolder",
          textAlign: "center",
        },
      },
      TableHeadRow: {
        style: {
          textAlign: "center",
          borderBottomWidth: "1px",
          borderBottomStyle: "dashed !important",
          borderBottomColor: "#f1f1f4",
        },
      },
      cells: {
        style: {
          justifyContent: "center",
          textAlign: "center",
          paddingTop: "1.25rem",
          paddingBottom: "1.25rem",
          color: "#78829d !important",
          fontWeight: "600",
        },
      },
    };
  }, []);

  const columns = useMemo(
    () => [
      {
        name: "اسم الموظف ",
        selector: (row) => row?.employee_name,
        sortable: true,
        cell: (d) => (d?.employee_name ? d?.employee_name : "--"),
        cellExport: (row) => row?.employee_name,
      },
      {
        name: "عدد المسافرين ",
        selector: (row) => row?.travelers,
        sortable: true,
        cell: (d) => (d?.travelers ? d?.travelers : "--"),
        cellExport: (row) => row?.travelers,
      },
      {
        name: " مقاعد الطيران ",
        selector: (row) => row?.flight_seats_count,
        sortable: true,
        cell: (d) => (d?.flight_seats_count ? d?.flight_seats_count : "--"),
        cellExport: (row) => row?.flight_seats_count,
      },
      {
        name: "عدد الغرف ",
        selector: (row) => row?.rooms_count,
        sortable: true,
        cell: (d) => (d?.rooms_count ? d?.rooms_count : "--"),
        cellExport: (row) => row?.rooms_count,
      },
      {
        name: "نوع الجولات ",
        selector: (row) => row?.type,
        sortable: true,
        cell: (d) => {
          if (d?.type === "public") {
            return (
              <span className=" py-3 px-4 fs-2 badge-success">جولات عامه</span>
            );
          } else if (d?.type === "private") {
            return (
              <span className=" py-3 px-4 fs-2 badge-danger">جولات خاصه</span>
            );
          } else {
            return (
              <span className=" py-3 px-4 fs-2 badge-info">
                جولات خاصه و عامه معا
              </span>
            );
          }
        },
        cellExport: (row) => row?.type,
      },
      {
        name: "الشركه  ",
        selector: (row) => row?.company,
        sortable: true,
        cell: (d) => (d?.company ? d?.company : "--"),
        cellExport: (row) => row?.company,
      },
      {
        name: "الاجراءات",
        selector: (row) => row?.id,
        sortable: true,
        cell: (d) => <CancelBookingModal book={d} />,
      },
    ],
    []
  );

  const tableData = {
    columns,
    data,
  };

  const paginationComponentOptions = {
    rowsPerPageText: " عرض الإدخالات",
    rangeSeparatorText: "إظهار من أصل مُدخلات",
    selectAllRowsItem: true,
    selectAllRowsItemText: "الكل",
  };

  return {
    customStyles,
    paginationComponentOptions,
    tableData,
    columns,
    data,
    // loading,
  };
};

export default useReservationTable;
