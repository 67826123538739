import { NavLink } from "react-router-dom";
import ReservationTable from "../../component/tables/ReservationTable";
import usePersistentTab from "../../../../../components/common/hooks/usePersistentTab";
import EditForm from "../../component/forms/EditTourForm";
import withGuard from "../../../../../utils/withGurard";

const EditTour = () => {
  const localStorageKey = "editTourActiveTab";
  const defaultTab = "tour";
  const { activeTab, handleSelectTab } = usePersistentTab(
    defaultTab,
    localStorageKey
  );

  return (
    <>
      <div className=" shadow-none position-relative overflow-hidden ">
        <div className="card-body py-3">
          <div className="row align-items-center">
            <div className="col-9">
              <h4 className="fw-semibold ">تعديل الرحله</h4>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <NavLink className="text-muted " to="/dashboard">
                      لوحة التحكم
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item">
                    <NavLink className="text-muted " to="/dashboard/tour">
                      جميع الرحلات
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    تعديل الرحله
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="card card-custom shadow">
        <div className="card-header card-header-stretch bg-white">
          <div className="card-toolbar">
            <ul className="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0">
              <li className="nav-item mr-2">
                <a
                  className={`nav-link ${activeTab === "tour" ? "active" : ""}`}
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_4"
                  onClick={() => handleSelectTab("tour")}
                >
                  الرحلات
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    activeTab === "reservation" ? "active" : ""
                  }`}
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_5"
                  onClick={() => handleSelectTab("reservation")}
                >
                  الحجوزات
                </a>
              </li>
            </ul>
          </div>

          <div className="mt-4">
            {activeTab === "reservation" && <ReservationTable />}
          </div>
        </div>
        <div className="card-body">
          <div className="tab-content" id="myTabContent">
            <div
              className={`tab-pane fade ${
                activeTab === "tour" ? "show active " : ""
              }`}
              id="kt_tab_pane_4"
              role="tabpanel"
            >
              <EditForm />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withGuard(EditTour);
