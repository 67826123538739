import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const fetchAllBooking = createAsyncThunk(
  "allBooking/fetchAllBooking",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    const token = localStorage.getItem("token");

    try {
      const { data } = await axios({
        method: "GET",
        url: `booking/stats/all/${id}`,
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export default fetchAllBooking;
