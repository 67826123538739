import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import useUsersTable from "../hooks/useTable";

const UsersTable = () => {
  const {
    customStyles,
    paginationComponentOptions,
    tableData,
    columns,
    data,
    loading,
  } = useUsersTable();

  return (
    <DataTableExtensions {...tableData} filterPlaceholder="بحث">
      <DataTable
        columns={columns}
        data={data}
        pagination
        paginationComponentOptions={paginationComponentOptions}
        highlightOnHover
        pointerOnHover
        customStyles={customStyles}
        defaultSortFieldId={1}
        persistTableHead
        noDataComponent={<span className="p-5">لا توجد بيانات لعرضها</span>}
        noContextMenu
        selectableRows
        selectableRowsHighlight
        //   progressPending={loading}
      />
    </DataTableExtensions>
  );
};

export default UsersTable;
