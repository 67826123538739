import { useState, useEffect } from "react";

// Custom hook for handling persistent tab state
const usePersistentTab = (defaultTab, localStorageKey) => {
 const [activeTab, setActiveTab] = useState(defaultTab);
  useEffect(() => {
    const storedActiveTab = localStorage.getItem(localStorageKey);
    setActiveTab(storedActiveTab || defaultTab);
  }, [localStorageKey, defaultTab]);

  useEffect(() => {
    localStorage.setItem(localStorageKey, activeTab);
  }, [activeTab, localStorageKey]);

  //  useEffect for cleanup
  useEffect(() => {
    return () => {
      // Reset data in localStorage when component unmounts
      localStorage.removeItem(localStorageKey);
    };
  }, [localStorageKey]);

  const handleSelectTab = (key) => {
    if (key) {
      setActiveTab(key);
    }
  };

  return { activeTab, handleSelectTab };
};

export default usePersistentTab;
