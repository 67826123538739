import { Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../../../../components/common/breadcrumb/Breadcrumb";
import AddForm from "../../component/forms/AddForm";
import withGuard from "../../../../../utils/withGurard";

const AddTour = () => {
  const navigate = useNavigate();
  const { country } = useParams();
  return (
    <>
      <Breadcrumb
        breadcrumbHeading="إضافة رحله جديده"
        dashboard="لوحة التحكم"
        breadcrumb1="جميع الرحلات"
        breadcrumbNavLink1={`/dashboard/tour/${country}`}
        breadcrumb="إضافة رحله"
      >
        <Button
          className="btn btn-primary"
          onClick={() => navigate(`/dashboard/tour/${country}`)}
        >
          عودة
        </Button>
      </Breadcrumb>
      <AddForm />
    </>
  );
};

export default withGuard(AddTour);
