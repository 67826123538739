const LayoutCard = ({ children, headerCard, toolbar }) => {
  return (
    <div className="card card-custom">
      {headerCard && (
        <div className="card-header border-0">
          <div className="card-title">
            <h2>{headerCard}</h2>
          </div>
          {toolbar === "true" && (
            <div className="card-toolbar">
              <div
                className="rounded-circle bg-success w-15px h-15px"
                id="kt_ecommerce_add_product_status"
              ></div>
            </div>
          )}
        </div>
      )}
      <div className="card-body">{children}</div>
    </div>
  );
};

export default LayoutCard;
