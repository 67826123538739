import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const editTours = createAsyncThunk("tour/editTours", async (tour, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  const token = localStorage.getItem("token");

  try {
    const { data } = await axios({
      method: "post",
      url: `/tours/update/${tour.id}`,
      data: tour,
      headers: { Authorization: `Bearer ${token}` },
    });
    // if (data?.status === 200) {
    //   dispatch(setMessage(`تم تعديل سبب الالغاء بنجاح`));
    // }

    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export default editTours;
