import { useEffect, useState } from "react";
import { Button, Nav, Tab } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// import { RootState } from "../../../../store";
// import ErrorMessageNotification from "../users_list_components/hooks/useMessageErrors";
import EditUserForm from "../../component/forms/EditUsersForm";
import ChangePassword from "../../component/forms/ChangePassword";
import Breadcrumb from "../../../../../components/common/breadcrumb/Breadcrumb";
import withGuard from "../../../../../utils/withGurard";

const EditUser = () => {
  //   const {error} = useSelector((state: RootState) => state.userManagagement)
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("");

  useEffect(() => {
    const storedActiveTab = localStorage.getItem("editUser");
    if (storedActiveTab) {
      setActiveTab(storedActiveTab);
    } else {
      setActiveTab("personalData");
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("editUser", activeTab);
  }, [activeTab]);
  useEffect(() => {
    return () => {
      localStorage.removeItem("editUser");
    };
  }, []);

  return (
    <>
      <Breadcrumb
        breadcrumbHeading=" المستخدمين"
        dashboard="لوحة التحكم"
        breadcrumb="تعديل المستخدم"
      >
        <Button
          className="btn btn-sm btn-primary p-3"
          onClick={() => navigate("/dashboard/users")}
        >
          {/* <i className="fa-solid text-white fa-arrow-left"></i> */}
          عودة
        </Button>
      </Breadcrumb>
      {/* {error && <ErrorMessageNotification error={error} />} */}

      <Tab.Container
        id="editUserTabs"
        activeKey={activeTab}
        onSelect={(key) => key && setActiveTab(key)}
      >
        <Nav
          variant="tabs"
          className="nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold"
        >
          <Nav.Item>
            <Nav.Link eventKey="personalData">البيانات الشخصية</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="password">ضبط كلمة المرور</Nav.Link>
          </Nav.Item>
        </Nav>

        <Tab.Content className="mt-5">
          <Tab.Pane eventKey="personalData">
            <EditUserForm />
          </Tab.Pane>
          <Tab.Pane eventKey="password">
            <ChangePassword />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
};

export default withGuard(EditUser);
