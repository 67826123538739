import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

//import { setMessage } from "./authSlice";

const authLogin = createAsyncThunk("auth/authLogin", async (user, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const { data } = await axios({
      method: "POST",
      url: `users/login`,
      data: user,
    });
    // if (data?.user && data.token) {
    //   dispatch(setMessage("تم الدخول  بنجاح"));
    // }
    return data;
  } catch (error) {
    return rejectWithValue(error.response.data.message);
  }
});

export default authLogin;
