import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import authLogin from "../../store/auth/authLogin";

const Login = () => {
  const { error } = useSelector((state) => state.logIn);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.logIn);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values) => {
      dispatch(authLogin(values))
        .unwrap()
        .then((res) => {
          localStorage.setItem("token", res.token);
          localStorage.setItem("user_id", res.user.id);
          localStorage.setItem("first_name", res.user.first_name);
          localStorage.setItem("last_name", res.user.last_name);
          localStorage.setItem("email", res.user.email);
          localStorage.setItem("role", res.user.role);
          localStorage.setItem("company", res.user.company);

          res.token && res.user.role && navigate("/dashboard");
        })
        .catch((err) => {
          console.log(err);
        });
    },
  });

  return (
    <div
      className="page-wrapper"
      id="main-wrapper"
      data-layout="vertical"
      data-navbarbg="skin6"
      data-sidebartype="full"
      data-sidebar-position="fixed"
      data-header-position="fixed"
    >
      <div className="position-relative overflow-hidden radial-gradient min-vh-100 d-flex align-items-center justify-content-center">
        <div className="d-flex align-items-center justify-content-center w-100">
          <div className="row justify-content-center w-100">
            <div className="col-md-8 col-lg-6 col-xxl-3">
              <div className="card mb-0">
                <div className="card-body">
                  <span className="text-nowrap logo-img text-center d-block py-3 w-100">
                    <img
                      src="../assets/images/logos/eqlogo.png"
                      width="120"
                      alt=""
                    />
                  </span>
                  <p className="text-center">Eqlaa</p>
                  {error && (
                    <div className="alert alert-danger">
                      هذا البريد الالكتروني غير مسجل بالنظام
                    </div>
                  )}
                  <form onSubmit={formik.handleSubmit}>
                    <div className="fv-row mb-8 fv-plugins-icon-container">
                      <input
                        className={`
                    form-control bg-transparent ${
                      formik.touched.email && formik.errors.email
                        ? "is-invalid"
                        : formik.values.email && !formik.errors.email
                        ? "is-valid"
                        : ""
                    }`}
                        type="email"
                        name="email"
                        autoComplete="off"
                        placeholder="البريد الإلكتروني"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                      />
                      {formik.errors.email && formik.touched.email ? (
                        <div className="text-danger mt-2">
                          {formik.errors.email}
                        </div>
                      ) : null}
                    </div>
                    <div className="fv-row mb-3 fv-plugins-icon-container">
                      <input
                        type="password"
                        autoComplete="off"
                        className={`
                    form-control bg-transparent ${
                      formik.touched.password && formik.errors.password
                        ? "is-invalid"
                        : formik.values.password && !formik.errors.password
                        ? "is-valid"
                        : ""
                    }`}
                        name="password"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                        placeholder="كلمة المرور"
                      />
                      {formik.errors.password && formik.touched.password ? (
                        <div className="text-danger mt-2">
                          {formik.errors.password}
                        </div>
                      ) : null}
                    </div>

                    <button
                      className="d-grid btn btn-primary gap-2d-flex justify-content-center w-100"
                      // onClick={() => navigate("/dashboard")}
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? (
                        <div
                          className="spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        "دخول"
                      )}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
