import React, { useEffect, useMemo } from "react";
// import { TableStyles } from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate} from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import fetchAllUsers from "../../../../../store/user-management/act/actFetchAllUsers";

const useUsersTable = () => {
  //helper hools
  // const { id } = useParams();
  const navigate = useNavigate();
  //   const dispatch = useDispatch();

  //state
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.usersSlice);
  useEffect(() => {
    dispatch(fetchAllUsers());
  }, [dispatch]);
  console.log(data);

  const customStyles = useMemo(() => {
    return {
      rows: {
        style: {
          minHeight: "110px",
          textAlign: "center",
          borderBottomWidth: "1px",
          borderBottomStyle: "dashed !important",
          borderBottomColor: "#f1f1f4",
        },
      },
      headCells: {
        style: {
          color: "#99a1b7",
          justifyContent: "center",
          fontWeight: "bolder",
          textAlign: "center",
        },
      },
      TableHeadRow: {
        style: {
          textAlign: "center",
          borderBottomWidth: "1px",
          borderBottomStyle: "dashed !important",
          borderBottomColor: "#f1f1f4",
        },
      },
      cells: {
        style: {
          justifyContent: "center",
          textAlign: "center",
          paddingTop: "1.25rem",
          paddingBottom: "1.25rem",
          color: "#78829d !important",
          fontWeight: "600",
        },
      },
    };
  }, []);

  const columns = useMemo(
    () => [
      {
        name: "الاسم الاول",
        selector: (row) => row.first_name,
        sortable: true,
        cell: (d) => (d.first_name ? d.first_name : "--"),
        cellExport: (row) => row.first_name,
      },
      {
        name: "الاسم الاخير ",
        selector: (row) => row.last_name,
        sortable: true,
        cell: (d) => (d.last_name ? d.last_name : "--"),
        cellExport: (row) => row.last_name,
      },
      {
        name: "البريد الالكتروني",
        selector: (row) => row.email,
        sortable: true,
        cell: (d) => (d.email ? d.email : "--"),
        cellExport: (row) => row.email,
      },
      {
        name: "اسم الشركة ",
        selector: (row) => row.company,
        sortable: true,
        cell: (d) => (d.company ? d.company : "--"),
        cellExport: (row) => row.company,
      },
      {
        name: "الدور الوظيفي",
        selector: (row) => row.role,
        sortable: true,
        cell: (d) => (d.company ? d.role : "--"),
        cellExport: (row) => row.role,
      },
      {
        name: "الاجراءات",
        selector: (row) => row?.id,
        sortable: true,
        cell: (d) => (
          <>
            <button
              onClick={() => navigate(`/dashboard/users/edit/${d.id}`)}
              className="btn btn-sm bg-success text-white"
            >
              تعديل
            </button>
          </>
        ),
      },
    ],
    [navigate]
  );

  const tableData = {
    columns,
     data,
  };

  const paginationComponentOptions = {
    rowsPerPageText: " عرض الإدخالات",
    rangeSeparatorText: "إظهار من أصل مُدخلات",
    selectAllRowsItem: true,
    selectAllRowsItemText: "الكل",
  };

  return {
    customStyles,
    paginationComponentOptions,
    tableData,
    columns,
     data,
    //  loading,
  };
};

export default useUsersTable;
