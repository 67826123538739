import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const fetchAllUsers = createAsyncThunk(
  "userManagement/fetchAllUsers",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    const token = localStorage.getItem("token");
    try {
      const { data } = await axios({
        method: "GET",
        url: `users/all`,
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export default fetchAllUsers;
