export const customStyles = {
  rows: {
    style: {
      minHeight: "72px",
      textAlign: "center",
      borderBottomWidth: "1px",
      borderBottomStyle: "dashed !important",
      borderBottomColor: "#f1f1f4",
    },
  },
  headCells: {
    style: {
      color: "#99a1b7",
      justifyContent: "center",
      // backgroundColor: '#f1f5f9',
      fontWeight: "bolder",
      textAlign: "center",
    },
  },
  TableHeadRow: {
    style: {
      // backgroundColor: '#f1f5f9',
      textAlign: "center",
      borderBottomWidth: "1px",
      borderBottomStyle: "dashed !important",
      borderBottomColor: "#f1f1f4",
    },
  },
  cells: {
    style: {
      justifyContent: "center",
      textAlign: "center",
      paddingTop: "1.25rem",
      paddingBottom: "1.25rem",
      color: "#78829d !important",
      fontWeight: "600",
    },
  },
};

export const paginationComponentOptions = {
  rowsPerPageText: " عرض الإدخالات",
  rangeSeparatorText: "إظهار من أصل مُدخلات",
  selectAllRowsItem: true,
  selectAllRowsItemText: "الكل",
};
