import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const withGuard = (Component) => {
  const Wrapper = () => {
    const navigate = useNavigate();
    useEffect(() => {
      if (
        !localStorage.getItem("token") &&
        !localStorage.getItem("id") &&
        !localStorage.getItem("role") &&
        !localStorage.getItem("email")
      ) {
        navigate("/");
      }
    }, [navigate]);

    return <Component />;
  };

  return Wrapper;
};

export default withGuard;
