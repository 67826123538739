import { Form } from "react-bootstrap";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import fetchSingleTour from "../../../../../store/tours/act/fetchsingleTour";
import editTours from "../../../../../store/tours/act/editTours";
import useCountriesData from "../../../countries/components/hooks/useData";
function EditForm() {
  const { singleTour } = useSelector((state) => state.toursSlice);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { data } = useCountriesData();

  useEffect(() => {
    dispatch(fetchSingleTour(id));
  }, [dispatch, id]);

  const formik = useFormik({
    initialValues: {
      id: singleTour?.id,
      title: singleTour?.title,
      start_date: singleTour?.start_date,
      end_date: singleTour?.end_date,
      company: singleTour?.company,
      type: singleTour?.type,
      rooms_count: singleTour?.rooms_count,
      country_id: singleTour?.country_id,
      flight_seats_count: singleTour?.flight_seats_count,
    },
    // validationSchema: UserSchema,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      dispatch(editTours(values))
        .unwrap()
        .then(() => navigate("/dashboard/tour"))
        .catch((error) => {
          console.log(error);
        });
    },
  });
  return (
    <div>
      <h5 className="card-title fw-semibold mb-4">اضافه رحله جديده</h5>
      <Form onSubmit={formik.handleSubmit} className="py-2">
        <div className="row mb-3">
          <div className="col-lg-4">
            <label>اسم الرحله</label>
            <input
              className="form-control"
              type="text"
              name="title"
              value={formik.values.title}
              onChange={formik.handleChange}
            />
          </div>
          <div className="col-lg-4">
            <label>تاريخ البدايه</label>
            <input
              className="form-control"
              type="date"
              name="start_date"
              value={formik.values.start_date}
              onChange={formik.handleChange}
            />
          </div>
          <div className="col-lg-4">
            <label>تاريخ النهايه</label>
            <input
              className="form-control"
              type="date"
              name="end_date"
              value={formik.values.end_date}
              onChange={formik.handleChange}
            />
          </div>
          <div className="col-lg-4 mt-5">
            <label>الوجهة</label>
            <select
              className="form-control"
              name="country_id"
              onChange={formik.handleChange}
              value={formik.values.country_id}
              aria-label="Select example"
            >
              <option value="" disabled>
                اختر الوجهة
              </option>
              {data &&
                data.length > 0 &&
                data.map((el) => (
                  <option value={el.id} key={el.id}>
                    {el.name}
                  </option>
                ))}
            </select>
          </div>
          <div className="col-lg-4 mt-5">
            <label> نوع الرحله</label>
            <select
              className="form-control"
              name="type"
              onChange={formik.handleChange}
              value={formik.values.type}
              aria-label="Select example"
            >
              <option value="" disabled>
                {" "}
                اختر نوع الرحله
              </option>
              <option value="private">جولات خاصه </option>
              <option value="public"> جولات عامه</option>
              <option value="both"> جولات خاصه و عامه معا</option>
            </select>
          </div>

          <div className="col-lg-4 mt-5">
            <label> الشركه</label>
            <select
              className="form-control"
              name="company"
              value={formik.values.company}
              onChange={formik.handleChange}
              aria-label="Select example"
            >
              <option value="" disabled>
                {" "}
                اختر الشركه
              </option>
              <option value="Eqlaa">اقلاع </option>
              <option value="RT">RT </option>
            </select>
          </div>
          <div className="col-lg-6 mt-5">
            <label>عدد الغرف</label>
            <input
              className="form-control"
              type="number"
              name="rooms_count"
              value={formik.values.rooms_count}
              onChange={formik.handleChange}
            />
          </div>
          <div className="col-lg-6 mt-5">
            <label>عدد مقاعد الطيران</label>
            <input
              className="form-control"
              type="number"
              name="flight_seats_count"
              value={formik.values.flight_seats_count}
              onChange={formik.handleChange}
            />
          </div>
        </div>
        <button className="btn btn-warning mt-2 px-4" type="submit">
          تعديل
        </button>
      </Form>
    </div>
  );
}

export default EditForm;
