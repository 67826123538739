import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const cancelBooking = createAsyncThunk(
  "tour/cancelBooking",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await axios({
        method: "post",
        url: `booking/cancel/${id}`,
      });
      //   if (data.status === 200) {
      //     dispatch(setMessage("تم حذف الحجز بنجاح!"));
      //   }
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export default cancelBooking;
