import React from "react";
import Breadcrumb from "../../../../components/common/breadcrumb/Breadcrumb";
import { Link } from "react-router-dom";
import EditCountryForm from "../components/forms/EditCountryForm";
import useCountryDetails from "../components/hooks/useDetails";

const EditCountry = () => {
  const { country, loading } = useCountryDetails();
  return (
    <>
      <Breadcrumb
        breadcrumbHeading="تعديل الوجهة "
        dashboard="لوحة التحكم"
        breadcrumb1="جميع الوجهات"
        breadcrumbNavLink1={"/dashboard/countries"}
        breadcrumb="تعديل الوجهة"
      >
        <Link className="btn btn-primary" to={"/dashboard/countries"}>
          عودة
        </Link>
      </Breadcrumb>
      <EditCountryForm country={country} />
    </>
  );
};

export default EditCountry;
