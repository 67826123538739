import { useCallback } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import cancelBooking from "../../../../../store/tours/act/cancelBooking";
import fetchAllReservations from "../../../../../store/tours/act/fetchAllReservation";

const CancelBookingModal = ({ book }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const dispatch = useDispatch();
  const { id } = useParams();

  const deleteProblemHandler = useCallback(
    (id) => {
      dispatch(cancelBooking(book.id))
        .unwrap()
        .then(() => {
          dispatch(fetchAllReservations(id));
        });
      handleClose();
    },
    [dispatch, book]
  );
  return (
    <>
      <button className="btn btn-danger" onClick={handleShow}>
        الغاء الحجز
      </button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="bg-danger ">
          <Modal.Title className="text-white">تأكيد</Modal.Title>
        </Modal.Header>
        <Modal.Body>هل أنت متأكد من حذف الحجز ؟</Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleClose}>
            الغاء
          </Button>
          <Button
            className="pe-20"
            variant="danger"
            onClick={() => deleteProblemHandler(id)}
          >
            حذف
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CancelBookingModal;
