import "react-data-table-component-extensions/dist/index.css";
import { Table } from "react-bootstrap";
import fetchAllBooking from "../../../../../store/booking/act/fetchAllBooking";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import AddBookingModal from "../modal/AddBookingModal";
import LoadingLayout from "../../../../../components/common/Loading_page/LoaderPage";
import { useParams } from "react-router-dom";

const BookingTable = () => {
  const { loading, data } = useSelector((state) => state.bookingSlice);
  const dispatch = useDispatch();
  const { country } = useParams();

  useEffect(() => {
    dispatch(fetchAllBooking(country));
  }, [dispatch, country]);

  function convertPercentage(data) {
    // Remove trailing zeros and unnecessary decimal points
    return (
      parseFloat(data)
        .toFixed(2)
        .replace(/\.?0*$/, "") + "%"
    );
  }
  return (
    <LoadingLayout loading={loading}>
      <div className="table-responsive text-center">
        <Table bordered responsive="sm" className=" fs-2 align-middle ">
          <thead>
            <tr>
              <th>#</th>
              <th
                className="bg-gray text-white"
                style={{ paddingLeft: "40px", paddingRight: "40px" }}
              >
                رقم الرحله
              </th>
              <th className="bg-gray text-white" colSpan={2}>
                تاريخ الرحله
              </th>
              <th
                className="bg-gray text-white"
                style={{ paddingLeft: "40px", paddingRight: "40px" }}
              >
                نوع الجولات
              </th>
              {localStorage.getItem("role") === "agent" && (
                <>
                  <th className="bg-gray text-white">متبقي عدد الغرف </th>
                  <th className="bg-gray text-white">متبقي مقاعد الطيران </th>
                </>
              )}
              {localStorage.getItem("role") === "admin" && (
                <>
                  <th className="bg-gray text-white">اجمالي عدد الغرف </th>
                  <th className="bg-gray text-white">اجمالي مقاعد الطيران </th>
                </>
              )}

              <th colSpan="2">اجمالي عدد المسافرين</th>
              {localStorage.getItem("role") === "admin" && (
                <>
                  <th colSpan="2">اجمالي حجوزات الطيران</th>{" "}
                  <th colSpan="2">اجمالي عدد الغرف المحجوزه</th>
                  <th className="bg-warning text-white " colSpan="3">
                    حجوزات اقلاع
                  </th>
                  <th className="bg-success text-white " colSpan="3">
                    حجوزات RT
                  </th>
                </>
              )}
              <th className="bg-gray text-white" rowSpan={2}>
                الاجراءات
              </th>
            </tr>
            <tr>
              <th></th> {/* Empty cell for the row header */}
              <th className="bg-gray"></th>{" "}
              {/* Empty cell for the main column */}
              <th
                className="bg-gray text-white"
                style={{ paddingLeft: "40px", paddingRight: "40px" }}
              >
                من
              </th>{" "}
              {/* Empty cell for the main column */}
              <th
                className="bg-gray text-white"
                style={{ paddingLeft: "50px", paddingRight: "50px" }}
              >
                الي
              </th>{" "}
              {/* Empty cell for the main column */}
              <th className="bg-gray"> </th>{" "}
              {/* Empty cell for the main column */}
              <th className="bg-gray"></th>{" "}
              {/* Empty cell for the main column */}
              <th className="bg-gray"></th>
              <th>جولات خاصه</th>
              <th>جولات عامه</th>
              {localStorage.getItem("role") === "admin" && (
                <>
                  <th> الاجمالي</th>
                  <th>نسبه الاشغال</th>
                  <th> الاجمالي</th>
                  <th>نسبه الاشغال</th>
                  <th className="bg-warning text-white ">غرف </th>
                  <th className="bg-warning text-white "> مسافرين</th>
                  <th className="bg-warning text-white "> مقاعد</th>
                  <th className="bg-success text-white ">غرف </th>
                  <th className="bg-success text-white "> مسافرين</th>
                  <th className="bg-success text-white "> مقاعد</th>
                </>
              )}
              {/* Empty cell for the last single column */}
            </tr>
          </thead>
          <tbody style={{ lineHeight: "2" }}>
            {data.length > 0 &&
              data.map((tour, index) => (
                <tr
                  key={tour.tour.id}
                  style={{ margin: "0", padding: "0" }}
                  className={
                    tour.rooms_booked_percentage >= Number(100) ||
                    tour.flight_seats_booked_percentage >= Number(100)
                      ? "bg-danger-subtle"
                      : ""
                  }
                >
                  <td>{index + 1}</td>
                  <td>{tour.tour.title}</td>
                  <td>{tour.tour.start_date} </td>
                  <td>{tour.tour.end_date}</td>
                  <td>
                    {tour.tour.type === "public" && "جولات عامه"}
                    {tour.tour.type === "private" && "جولات خاصه"}
                    {tour.tour.type === "both" && "جولات خاصه و عامه"}
                  </td>
                  {localStorage.getItem("role") === "agent" && (
                    <>
                      <td>{tour.agent_remaining_rooms}</td>
                      <td>{tour.agent_remaining_seats}</td>
                    </>
                  )}
                  {localStorage.getItem("role") === "admin" && (
                    <>
                      <td>{tour.tour.rooms_count}</td>
                      <td>{tour.tour.flight_seats_count}</td>
                    </>
                  )}

                  <td>{tour.total_booked_private}</td>
                  <td>{tour.total_booked_public}</td>

                  {localStorage.getItem("role") === "admin" && (
                    <>
                      <td>{tour.totalBookedFlightSeats}</td>
                      <td>
                        {tour.flight_seats_booked_percentage &&
                          convertPercentage(
                            tour.flight_seats_booked_percentage
                          )}
                      </td>

                      <td>{tour.totalBookedRooms}</td>
                      <td>
                        {tour.rooms_booked_percentage &&
                          convertPercentage(tour.rooms_booked_percentage)}
                      </td>
                      <td>{tour.eqlaa_booked_rooms}</td>
                      <td>{tour.eqlaa_travelers}</td>
                      <td>{tour.eqlaa_flight_seats}</td>
                      <td>{tour.rt_booked_rooms}</td>
                      <td>{tour.rt_travelers}</td>
                      <td>{tour.rt_flight_seats}</td>
                    </>
                  )}
                  <td>
                    {tour.rooms_booked_percentage >= Number(100) &&
                    tour.flight_seats_booked_percentage >= Number(100) ? (
                      "نفذ الحجز"
                    ) : (
                      <AddBookingModal
                        tourId={tour.tour.id}
                        type={tour.tour.type}
                      />
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    </LoadingLayout>
  );
};

export default BookingTable;
