import React from "react";
import ReactDOM from "react-dom/client";
//routes
import { createBrowserRouter, RouterProvider } from "react-router-dom";
//fonts
import "remixicon/fonts/remixicon.css";
//styles
import "./index.css";
import reportWebVitals from "./reportWebVitals";
//store
import { Provider } from "react-redux";
import store from "./store";
//services
import "./services/axios-global";
//pages
import ErrorPage from "./pages/errorPage/ErrorPage";
import Login from "./pages/login_page/Login";
import App from "./App";
import AddTour from "./app/modules/tour/layout/add_tour/AddTour";
import EditTour from "./app/modules/tour/layout/edit_tour/EditTour";
import AllTours from "./app/modules/tour/layout/all_tour/AllTour";
import AddUsers from "./app/modules/users/layout/add_users/AddUsers";
import UsersListPage from "./app/modules/users/layout/all_users/AllUsers";
import EditUser from "./app/modules/users/layout/edit_users/EditUsers";
import AddBooking from "./app/modules/booking/layout/add_booking/AddBooking";
import GuardedRoute from "../src/utils/GuardedRoute";
import AllCountries from "./app/modules/countries/layouts/AllCountries";
import AddCountry from "./app/modules/countries/layouts/AddCountry";
import EditCountry from "./app/modules/countries/layouts/EditCountry";
import AllBooking from "./app/modules/booking/layout/all_booking/AllBooking";
import CountriesCardsPage from "./app/modules/countries/layouts/countries/CountriesCardsPage";

// import Loadable from "./components/quailty-ui/Loading_page/Loadable";
// import { lazy } from "react";

// const AllCalls = Loadable(
//   lazy(() => import("./components/pages/all_calls/AllCalls"))
// );

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/dashboard",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: (
          <GuardedRoute userRoles={["admin", "agent"]}>
            <CountriesCardsPage breadcrumbHeading="الحجوزات" route="bookings" />
          </GuardedRoute>
        ),
      },
      {
        path: "/dashboard/users",
        element: (
          <GuardedRoute userRoles={["admin"]}>
            <UsersListPage />
          </GuardedRoute>
        ),
      },
      {
        path: "/dashboard/users/add",
        element: (
          <GuardedRoute userRoles={["admin"]}>
            <AddUsers />
          </GuardedRoute>
        ),
      },
      {
        path: "/dashboard/users/edit/:id",
        element: (
          <GuardedRoute userRoles={["admin"]}>
            <EditUser />
          </GuardedRoute>
        ),
      },
      {
        path: "/dashboard/bookings",
        element: (
          <GuardedRoute userRoles={["admin", "agent"]}>
            <CountriesCardsPage breadcrumbHeading="الحجوزات" route="bookings" />
          </GuardedRoute>
        ),
      },
      {
        path: "/dashboard/bookings/:country",
        element: (
          <GuardedRoute userRoles={["admin", "agent"]}>
            <AllBooking />
          </GuardedRoute>
        ),
      },
      {
        path: "/dashboard/bookings/add",
        element: (
          <GuardedRoute userRoles={["admin", "agent"]}>
            <AddBooking />
          </GuardedRoute>
        ),
      },
      {
        path: "/dashboard/tour",
        element: (
          <GuardedRoute userRoles={["admin", "agent"]}>
            <CountriesCardsPage breadcrumbHeading="الرحلات" route="tour" />
          </GuardedRoute>
        ),
      },
      {
        path: "/dashboard/tour/:country",
        element: (
          <GuardedRoute userRoles={["admin", "agent"]}>
            <AllTours />
          </GuardedRoute>
        ),
      },
      {
        path: "/dashboard/tour/add/:country",
        element: (
          <GuardedRoute userRoles={["admin", "agent"]}>
            <AddTour />
          </GuardedRoute>
        ),
      },
      {
        path: "/dashboard/tour/edit/:id",
        element: (
          <GuardedRoute userRoles={["admin", "agent"]}>
            <EditTour />
          </GuardedRoute>
        ),
      },

      // countries
      {
        path: "/dashboard/countries",
        element: (
          <GuardedRoute userRoles={["admin"]}>
            <AllCountries />
          </GuardedRoute>
        ),
      },
      {
        path: "/dashboard/countries/add",
        element: (
          <GuardedRoute userRoles={["admin"]}>
            <AddCountry />
          </GuardedRoute>
        ),
      },
      {
        path: "/dashboard/countries/edit/:id",
        element: (
          <GuardedRoute userRoles={["admin"]}>
            <EditCountry />
          </GuardedRoute>
        ),
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
