import "./loader.css";
import Spinner from "react-bootstrap/Spinner";

const LoadingLayout = ({ loading, children }) => {
  return (
    <>
      {loading ? (
        <div className="fallback-spinner">
          <div className="loading">
            <Spinner animation="border" />;
          </div>
        </div>
      ) : (
        children
      )}
    </>
  );
};

export default LoadingLayout;
