import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import fetchAllTours from "../../../../../store/tours/act/fetchAllTours";
import { resetData } from "../../../../../store/tours/toursSlice";
import { useParams } from "react-router-dom";

const useToursData = () => {
  //state
  const { loading, data } = useSelector((state) => state.toursSlice);

  //helper hooks
  const dispatch = useDispatch();
  const { country } = useParams();
  // fetch all Complaints
  useEffect(() => {
    dispatch(fetchAllTours(country));

    return () => resetData();
  }, [dispatch, country]);

  return { data, loading };
};

export default useToursData;
