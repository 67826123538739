/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */

import AdminSidebar from "./adminSidebar/AdminSidebar";
// import AuditorSidebar from "./auditorSidebar/AuditorSidebar";
// import EvaluatorSidebar from "./evaluatorSidebar/EvaluatorSidebar";
// import QualityManagerSidebar from "./qualityManagerSidebar/QualityManagerSidebar";
// import SupervisorSidebar from "./supervisorSidebar/SupervisorSidebar";

/* eslint-disable react/style-prop-object */
const MenuSidebar = () => {
  // const roleSidebarHandler = () => {
  //   if (localStorage.getItem("role") === "System Admin") {
  //     return <AdminSidebar />;
  //   } else if (localStorage.getItem("role") === "Evaluator") {
  //     return <EvaluatorSidebar />;
  //   } else if (localStorage.getItem("role") === "Employee Supervisor") {
  //     return <SupervisorSidebar />;
  //   } else if (localStorage.getItem("role") === "Quality Auditor") {
  //     return <AuditorSidebar />;
  //   } else if (localStorage.getItem("role") === "Quality Dept. Manager") {
  //     return <QualityManagerSidebar />;
  //   }
  // };
  return (
    <>
      <AdminSidebar />
    </>
  );
};

export default MenuSidebar;
