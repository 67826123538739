import { createSlice } from "@reduxjs/toolkit";
import addTours from "./act/addTours";
import editTours from "./act/editTours";
import fetchSingleTour from "./act/fetchsingleTour";
import fetchAllTours from "./act/fetchAllTours";
import fetchAllReservations from "./act/fetchAllReservation";
import cancelBooking from "./act/cancelBooking";

const initialState = {
  data: [],
  error: null,
  loading: false,
  singleTour: null,
};

const toursSlice = createSlice({
  name: "tours",
  initialState,
  reducers: {
    resetData: (state) => (state.data = []),
  },
  extraReducers: (builder) => {
    // ========================== add tours ==========================
    builder.addCase(addTours.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(addTours.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
    });
    builder.addCase(addTours.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // ========================== edit tours ==========================
    builder.addCase(editTours.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(editTours.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(editTours.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // ========================== fetchSingle tour ==========================
    builder.addCase(fetchSingleTour.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchSingleTour.fulfilled, (state, action) => {
      state.loading = false;
      state.singleTour = action.payload.tour;
    });
    builder.addCase(fetchSingleTour.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // ========================== fetchAll tours ==========================
    builder.addCase(fetchAllTours.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchAllTours.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.tours;
    });
    builder.addCase(fetchAllTours.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // ========================== fetchAllReservations ==========================
    builder.addCase(fetchAllReservations.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchAllReservations.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.bookings;
    });
    builder.addCase(fetchAllReservations.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // ========================== delete Problem ==========================
    builder.addCase(cancelBooking.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(cancelBooking.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(cancelBooking.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const { resetData } = toursSlice.actions;

export default toursSlice.reducer;
