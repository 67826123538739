import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const actEditCountry = createAsyncThunk(
  "countriesSlice/actEditCountry",
  async (country, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const { data } = await axios({
        method: "post",
        url: `/countries/update/${country.id}`,
        data: { name: country.name },
      });
      // if (data?.status === 200) {
      //   dispatch(setMessage(`تم تعديل سبب الالغاء بنجاح`));
      // }

      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export default actEditCountry;
