import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import DataTable from "react-data-table-component";
import { useMemo } from "react";
import { customStyles, paginationComponentOptions } from "./tableHelper";

const Table = ({ data, columns, loading }) => {
  const tableData = useMemo(() => ({ columns, data }), [columns, data]);

  return (
    <DataTableExtensions {...tableData} filterPlaceholder="بحث">
      <DataTable
        columns={columns}
        data={data}
        pagination
        paginationComponentOptions={paginationComponentOptions}
        highlightOnHover
        pointerOnHover
        customStyles={customStyles}
        defaultSortFieldId={1}
        persistTableHead
        noDataComponent={<span className="p-5">لا توجد بيانات لعرضها</span>}
        noContextMenu
        selectableRows
        selectableRowsHighlight
        progressPending={loading}
        //progressComponent={<loading />}
      />
    </DataTableExtensions>
  );
};

export default Table;
