import { createSlice } from "@reduxjs/toolkit";
import fetchAllUsers from "./act/actFetchAllUsers";
import addUser from "./act/actAddUser";
import editUser from "./act/actEditUser";
import fetchSingleUser from "./act/actFetchSingleUser";

// Import your action creators here
// import { fetchAllUsers, addUser, fetchSingleUser, editUser, changeUserPassword } from "./yourActionsFile";

const initialState = {
  data: [],
  singleUser: null,
  error: null,
  loading: false,
  loadingLayout: false, // Include loadingLayout in the initial state if needed
};

const usersSlice = createSlice({
  name: "userManagement",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    // ========================== get all users from server ==========================
    builder.addCase(fetchAllUsers.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchAllUsers.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.users;
      console.log(action.payload);
    });
    builder.addCase(fetchAllUsers.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // ========================== add new user ==========================
    builder.addCase(addUser.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(addUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // ========================== get single user ==========================
    builder.addCase(fetchSingleUser.pending, (state) => {
      state.loadingLayout = true;
      state.error = null;
    });
    builder.addCase(fetchSingleUser.fulfilled, (state, action) => {
      state.loadingLayout = false;
      state.singleUser = action.payload.user;
      //state.data = action.payload.user;
    });
    builder.addCase(fetchSingleUser.rejected, (state, action) => {
      state.loadingLayout = false;
      state.error = action.payload;
    });

    // ========================== edit single user ==========================
    builder.addCase(editUser.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(editUser.fulfilled, (state, action) => {
      state.loading = false;
      // state.data = action.payload.data;
      // console.log(action.payload);
    });
    builder.addCase(editUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // ========================== change user password ==========================
    //   builder.addCase(changeUserPassword.pending, (state) => {
    //     state.loading = true;
    //     state.error = null;
    //   });
    //   builder.addCase(changeUserPassword.rejected, (state, action) => {
    //     state.loading = false;
    //     state.error = action.payload;
    //   });
  },
});

export default usersSlice.reducer;
