import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const actFetchAllCountries = createAsyncThunk(
  "countriesSlice/actFetchAllCountries",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const { data } = await axios({
        method: "GET",
        url: `countries/all `,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export default actFetchAllCountries;
