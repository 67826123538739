import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import actFetchAllCountries from "../../../../../store/countries/act/actFetchAllCountries";
import { resetData } from "../../../../../store/countries/countriesSlice";

const useCountriesData = () => {
  //state
  const { loading, data } = useSelector((state) => state.countriesSlice);
  //helper hooks
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actFetchAllCountries());

    //reset all Countries when the user leaves the page
    return () => {
      dispatch(resetData());
    };
  }, [dispatch]);

  return { data, loading };
};

export default useCountriesData;
