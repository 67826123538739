import { createSlice } from "@reduxjs/toolkit";
import actAddCountry from "./act/actAddCountry";
import actEditCountry from "./act/actEditCountry";
import actFetchSingleCountry from "./act/actFetchSingleCountry";
import actFetchAllCountries from "./act/actFetchAllCountries";

const initialState = {
  data: [],
  error: null,
  loading: false,
  country: null,
};

const countriesSlice = createSlice({
  name: "countriesSlice",
  initialState,
  reducers: {
    resetData: (state) => {
      state.data = [];
    },
    resetCountry: (state) => {
      state.country = null;
    },
  },
  extraReducers: (builder) => {
    // ========================== actAddCountry ==========================
    builder.addCase(actAddCountry.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(actAddCountry.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(actAddCountry.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // ========================== actEditCountry ==========================
    builder.addCase(actEditCountry.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(actEditCountry.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(actEditCountry.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // ========================== actFetchSingleCountry ==========================
    builder.addCase(actFetchSingleCountry.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(actFetchSingleCountry.fulfilled, (state, action) => {
      state.loading = false;
      state.country = action.payload.country;
    });
    builder.addCase(actFetchSingleCountry.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // ========================== actFetchAllCountries ==========================
    builder.addCase(actFetchAllCountries.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(actFetchAllCountries.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.countries;
    });
    builder.addCase(actFetchAllCountries.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const { resetData, resetCountry } = countriesSlice.actions;

export default countriesSlice.reducer;
