import { useFormik } from "formik";
import { Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";

import { RootState } from "../../../../../store";

const ChangePassword = () => {
  //   const {loading} = useSelector((state: RootState) => state.userManagagement)

  //helper hooks
  //   const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
  const navigate = useNavigate();
  const { id } = useParams();

  const formik = useFormik({
    initialValues: {
      id: id ?? "",
      password: "",
      password_confirmation: "",
    },
    // validationSchema: changePasswordSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values) => {
      console.log(values);
      //   dispatch(changeUserPassword(values))
      //     .unwrap()
      //     .then(() => {
      //       navigate("/dashboard/users");
      //     });
    },
  });
  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row>
        <Col md={6}>
          <div className="mb-10">
            <label
              htmlFor="exampleFormControlInput1"
              className="required form-label"
            >
              كلمة المرور
            </label>
            <input
              type="password"
              className={`form-control  ${
                formik.touched.password && formik.errors.password
                  ? "is-invalid"
                  : formik.values.password && !formik.errors.password
                  ? "is-valid"
                  : ""
              }`}
              name="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              autoComplete="off"
              placeholder="ادخل  كلمة المرور"
            />
            {formik.errors.password && formik.touched.password ? (
              <div className="text-danger mt-2">{formik.errors.password}</div>
            ) : null}
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-10">
            <label
              htmlFor="exampleFormControlInput1"
              className="required form-label"
            >
              اعد كلمة المرور
            </label>
            <input
              type="password"
              className={`form-control  ${
                formik.touched.password_confirmation &&
                formik.errors.password_confirmation
                  ? "is-invalid"
                  : formik.values.password_confirmation &&
                    !formik.errors.password_confirmation
                  ? "is-valid"
                  : ""
              }`}
              name="password_confirmation"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password_confirmation}
              autoComplete="off"
              placeholder="اعد كتابة كلمة المرور"
            />
            {formik.errors.password_confirmation &&
            formik.touched.password_confirmation ? (
              <div className="text-danger mt-2">
                {formik.errors.password_confirmation}
              </div>
            ) : null}
          </div>
        </Col>
        <Col sm={2}>
          <div className="py-3">
            <button
              type="submit"
              className="btn btn-success w-100 "
              // disabled={loading || !(formik.isValid && formik.dirty)}
            >
              {/* {loading ? (
              <span className="spinner-border spinner-border-sm" />
            ) : (
              <>تعديل كلمة المرور</>
            )} */}
              <>تعديل كلمة المرور</>
            </button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default ChangePassword;
