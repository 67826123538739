import React from "react";
import Breadcrumb from "../../../../components/common/breadcrumb/Breadcrumb";
import { useNavigate } from "react-router-dom";
import LayoutCard from "../../../../components/common/card/LayoutCard";
import CountriesTable from "../components/tables/CountriesTable";

const AllCountries = () => {
  const navigate = useNavigate();
  return (
    <>
      <Breadcrumb
        breadcrumbHeading="الوجهات"
        dashboard="لوحة التحكم"
        breadcrumb="جميع الوجهات"
      >
        <button
          className="btn btn-primary"
          onClick={() => navigate("/dashboard/countries/add")}
        >
          أضف وجهة
        </button>
      </Breadcrumb>
      <LayoutCard>
        <CountriesTable />
      </LayoutCard>
    </>
  );
};

export default AllCountries;
