/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { NavLink } from "react-router-dom";
import logo from "../../../assets/images/eqlogo.png";

/* eslint-disable react/style-prop-object */
const AdminSidebar = () => {
  return (
    <aside className="left-sidebar">
      <div>
        <div className="brand-logo d-flex align-items-center justify-content-between">
          <NavLink
            to="/dashboard"
            style={{
              display: "inline-block",
              margin: "auto",
              textAlign: "center",
            }}
            className="text-nowrap logo-img"
          >
            <img src={logo} width="80" alt="" />
          </NavLink>
          <div
            className="close-btn d-xl-none d-block sidebartoggler cursor-pointer"
            id="sidebarCollapse"
          >
            <i className="ti ti-x fs-8"></i>
          </div>
        </div>
        <nav className="sidebar-nav scroll-sidebar" data-simplebar="">
          <ul id="sidebarnav">
            <li className="nav-small-cap">
              <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
              <span className="hide-menu" style={{ fontSize: "17px" }}>
                الاجراءات
              </span>
            </li>
            <li className="sidebar-item">
              <NavLink className="sidebar-link" to="/dashboard/countries">
                <span>
                  <i className="ti ri-terminal-window-fill"></i>
                </span>
                <span className="hide-menu">الوجهات</span>
              </NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink className="sidebar-link" to="/dashboard/tour">
                <span>
                  <i className="ti ri-terminal-window-fill"></i>
                </span>
                <span className="hide-menu">الرحلات</span>
              </NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink className="sidebar-link" to="/dashboard/bookings">
                <span>
                  <i className="ti ri-terminal-window-fill"></i>
                </span>
                <span className="hide-menu">الحجوزات</span>
              </NavLink>
            </li>
            {localStorage.getItem("token") &&
              localStorage.getItem("role") === "admin" && (
                <li className="sidebar-item">
                  <NavLink className="sidebar-link" to="/dashboard/users">
                    <span>
                      <i className="ti ri-terminal-window-line"></i>
                    </span>
                    <span className="hide-menu">المستخدمين</span>
                  </NavLink>
                </li>
              )}
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default AdminSidebar;
