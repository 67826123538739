import { Button } from "react-bootstrap";

import { useNavigate } from "react-router-dom";

import AddUserForm from "../../component/forms/AddUsersForm";
import Breadcrumb from "../../../../../components/common/breadcrumb/Breadcrumb";
import withGuard from "../../../../../utils/withGurard";

const AddUsers = () => {
  const navigate = useNavigate();
  return (
    <>
      <Breadcrumb
        breadcrumbHeading="إضافة مستخدم جديد"
        dashboard="لوحة التحكم"
        breadcrumb1="جميع المستخدمين"
        breadcrumbNavLink1={"/dashboard/users"}
        breadcrumb="إضافة مستخدم"
      >
        <Button
          className="btn btn-sm btn-primary"
          onClick={() => navigate("/dashboard/users")}
        >
          عودة
        </Button>
      </Breadcrumb>

      <AddUserForm />
    </>
  );
};

export default withGuard(AddUsers);
