import React from "react";
import Breadcrumb from "../../../../components/common/breadcrumb/Breadcrumb";
import { Link } from "react-router-dom";
import AddCountryForm from "../components/forms/AddCountryForm";

const AddCountry = () => {
  return (
    <>
      <Breadcrumb
        breadcrumbHeading="إضافة وجهة جديده"
        dashboard="لوحة التحكم"
        breadcrumb1="جميع الوجهات"
        breadcrumbNavLink1={"/dashboard/countries"}
        breadcrumb="إضافة وجهة"
      >
        <Link className="btn btn-primary" to={"/dashboard/countries"}>
          عودة
        </Link>
      </Breadcrumb>
      <AddCountryForm />
    </>
  );
};

export default AddCountry;
