import React from "react";

import Breadcrumb from "../../../../../components/common/breadcrumb/Breadcrumb";
import BookingTable from "../../components/table/BookingTable";
import LayoutCard from "../../../../../components/common/card/LayoutCard";
import withGuard from "../../../../../utils/withGurard";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const AllBooking = () => {
  const navigate = useNavigate();
  return (
    <>
      <Breadcrumb
        breadcrumbHeading="ادارة الحجوزات"
        dashboard="لوحة التحكم"
        breadcrumb="جميع الحجوزات"
      >
        <Button
          className="btn btn-primary"
          onClick={() => navigate(`/dashboard/bookings`)}
        >
          عودة
        </Button>
      </Breadcrumb>
      <LayoutCard>
        <BookingTable />
      </LayoutCard>
    </>
  );
};

export default withGuard(AllBooking);
