import { Outlet } from "react-router-dom";
import "./App.css";
import MenuSidebar from "./templates/sidebar/Menu_Sidebar";
import Navbar from "./templates/navbar/Navbar";
import withGuard from "./utils/withGurard";
function App() {
  return (
    <>
      {localStorage.getItem("role") === "admin" ? (
        <div
          className="page-wrapper "
          id="main-wrapper"
          data-layout="vertical"
          data-navbarbg="skin6"
          data-sidebartype="full"
          data-sidebar-position="fixed"
          data-header-position="fixed"
        >
          <MenuSidebar />
          <div className="body-wrapper">
            <Navbar />
            <div className="container-fluid">
              <Outlet />
            </div>
          </div>
        </div>
      ) : (
        <div
          className="page-wrapper"
          id="main-wrapper"
          data-layout="vertical"
          data-navbarbg="skin6"
          data-sidebartype="full"
          data-sidebar-position="fixed"
          data-header-position="fixed"
        >
          <div className="body-wrapper ms-0">
            <Navbar />

            <div className="container-fluid">
              <Outlet />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default withGuard(App);
