import React from "react";

const Loop = ({ children, data }) => {
  const renderData = data?.map((el) =>
    React.cloneElement(children, { key: el.id, ...el })
  );

  return <>{renderData}</>;
};

export default Loop;
