import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const fetchSingleTour = createAsyncThunk(
  "tour/fetchSingleTour",
  async (id, thunkAPI) => {
    const { rejectWithValue} = thunkAPI;
    const token = localStorage.getItem("token");

    try {
      const { data } = await axios({
        method: "GET",
        url: `tours/${id}`,
        headers: { Authorization: `Bearer ${token}` },
      });
      // if (data?.status === 200) {
      //   dispatch(setMessage(`تم تعديل سبب الالغاء بنجاح`));
      // }

      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export default fetchSingleTour;

