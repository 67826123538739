import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";


const fetchSingleUser = createAsyncThunk(
  "userManagement/fetchSingleUser",
  async ( id , thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    const token = localStorage.getItem("token");

    try {
      const { data } = await axios({
        method: "GET",
        url: `users/${id}`,
        headers: { Authorization: `Bearer ${token}` },
      });

      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export default fetchSingleUser;
