import { Button } from "react-bootstrap";
import Breadcrumb from "../../../../../components/common/breadcrumb/Breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import ToursTable from "../../component/tables/TourTable";
import LayoutCard from "../../../../../components/common/card/LayoutCard";
import withGuard from "../../../../../utils/withGurard";
import useToursData from "../../component/hooks/useData";
import LoadingLayout from "../../../../../components/common/Loading_page/LoaderPage";

const AllTours = () => {
  const { data, loading } = useToursData();
  const navigate = useNavigate();
  const { country } = useParams();
  return (
    <LoadingLayout loading={loading}>
      <Breadcrumb
        breadcrumbHeading="ادارة الرحلات"
        dashboard="لوحة التحكم"
        breadcrumb="الرحلات"
      >
        <Button
          className="btn btn-primary"
          onClick={() => navigate(`/dashboard/tour/add/${country}`)}
        >
          اضافة رحله
        </Button>
      </Breadcrumb>
      <LayoutCard>
        <ToursTable data={data} />
      </LayoutCard>
    </LoadingLayout>
  );
};

export default withGuard(AllTours);
