import React from "react";
import Breadcrumb from "../../../../../components/common/breadcrumb/Breadcrumb";
import useCountriesData from "../../../countries/components/hooks/useData";
import Loop from "../../../../../components/common/loop/Loop";
import CountryCard from "../../components/cards/CountryCard";

const CountriesCardsPage = ({ breadcrumbHeading, route }) => {
  const { data } = useCountriesData();
  return (
    <>
      <Breadcrumb
        breadcrumbHeading={breadcrumbHeading}
        dashboard="لوحة التحكم"
        breadcrumb="جميع الوجهات"
      />
      <div className="row">
        <Loop data={data}>
          <CountryCard route={route} />
        </Loop>
      </div>
    </>
  );
};

export default CountriesCardsPage;
