import React from "react";
import { useNavigate } from "react-router-dom";

import Breadcrumb from "../../../../../components/common/breadcrumb/Breadcrumb";
import withGuard from "../../../../../utils/withGurard";

const AddBooking = () => {
  const navigate = useNavigate();

  return (
    <>
      <Breadcrumb
        breadcrumbHeading="ادارة المواعيد"
        dashboard="لوحة التحكم"
        breadcrumb="اضافة حجز"
        breadcrumb1="جميع الحجوزات"
        breadcrumbNavLink1="/dashboard/bookings"
      >
        <button
          className="btn btn-primary"
          onClick={() => navigate("/dashboard/bookings/add")}
        >
          اضافة موعد
        </button>
      </Breadcrumb>
    </>
  );
};

export default withGuard(AddBooking);
