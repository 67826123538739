import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";

const useToursTable = () => {
  const navigate = useNavigate();

  const customStyles = useMemo(() => {
    return {
      rows: {
        style: {
          minHeight: "110px",
          textAlign: "center",
          borderBottomWidth: "1px",
          borderBottomStyle: "dashed !important",
          borderBottomColor: "#f1f1f4",
        },
      },
      headCells: {
        style: {
          color: "#99a1b7",
          justifyContent: "center",
          fontWeight: "bolder",
          textAlign: "center",
        },
      },
      TableHeadRow: {
        style: {
          textAlign: "center",
          borderBottomWidth: "1px",
          borderBottomStyle: "dashed !important",
          borderBottomColor: "#f1f1f4",
        },
      },
      cells: {
        style: {
          justifyContent: "center",
          textAlign: "center",
          paddingTop: "1.25rem",
          paddingBottom: "1.25rem",
          color: "#78829d !important",
          fontWeight: "600",
        },
      },
    };
  }, []);

  const columns = useMemo(
    () => [
      {
        name: "اسم الرحله",
        selector: (row) => row.title,
        sortable: true,
        cell: (d) => (d.title ? d.title : "--"),
        cellExport: (row) => row.title,
      },
      {
        name: "تاريخ البدايه",
        selector: (row) => row.start_date,
        sortable: true,
        cell: (d) => (d.start_date ? d.start_date : "--"),
        cellExport: (row) => row.start_date,
      },
      {
        name: "تاريخ النهايه",
        selector: (row) => row.end_date,
        sortable: true,
        cell: (d) => (d.end_date ? d.end_date : "--"),
        cellExport: (row) => row.end_date,
      },
      {
        name: "نوع الرحله",
        selector: (row) => row.type,
        sortable: true,
        cell: (d) => {
          if (d?.type === "public") {
            return (
              <span className=" py-3 px-4 fs-2 badge-success">جولات عامه</span>
            );
          } else if (d?.type === "private") {
            return (
              <span className=" py-3 px-4 fs-2 badge-danger">جولات خاصه</span>
            );
          } else {
            return (
              <span className=" py-3 px-4 fs-2 badge-info">
                جولات خاصه و عامه معا
              </span>
            );
          }
        },
        cellExport: (row) => row.type,
      },

      {
        name: "عدد الغرف",
        selector: (row) => row.rooms_count,
        sortable: true,
        cell: (d) => (d.rooms_count ? d.rooms_count : "--"),
        cellExport: (row) => row.rooms_count,
      },
      {
        name: "عدد مقاعد الطيران",
        selector: (row) => row.flight_seats_count,
        sortable: true,
        cell: (d) => (d.flight_seats_count ? d.flight_seats_count : "--"),
        cellExport: (row) => row.flight_seats_count,
      },
      {
        name: "الاجراءات",
        selector: (row) => row?.id,
        sortable: true,
        cell: (d) => (
          <>
            <button
              onClick={() => navigate(`/dashboard/tour/edit/${d.id}`)}
              className="btn btn-sm bg-success text-white"
            >
              تعديل
            </button>
          </>
        ),
      },
    ],
    [navigate]
  );

  const paginationComponentOptions = {
    rowsPerPageText: " عرض الإدخالات",
    rangeSeparatorText: "إظهار من أصل مُدخلات",
    selectAllRowsItem: true,
    selectAllRowsItemText: "الكل",
  };

  return {
    customStyles,
    paginationComponentOptions,
    columns,
  };
};

export default useToursTable;
