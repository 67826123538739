import { Col, Form, Row } from "react-bootstrap";

import { useFormik } from "formik";

import { useDispatch } from "react-redux";
// import addUser from "../../../../../store/user-management/actAddNewUser";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../../../store";
import addUser from "../../../../../store/user-management/act/actAddUser";
// import { setError } from "../../../../../store/user-management/userManagagementSlice";

const AddUserForm = () => {
  //state
  //   const { loading } = useSelector((state: RootState) => state.userManagagement);
  // helper hooks
  //   const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //formik
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      company: "",
      role: "",
      password: "",
      password_confirmation: "",
    },
    // validationSchema: UserSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values) => {
      dispatch(addUser(values))
        .unwrap()
        .then(() => navigate("/dashboard/users/"))
        .catch((error) => {
          console.log(error);
        });
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit} className="py-2">
      <div className="card card-custom shadow">
        <div className="card-header card-header-stretch">
          <Row>
            <Col md={4}>
              <div className="mb-10">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="required form-label"
                >
                  الاسم الأول
                </label>
                <input
                  type="text"
                  className={`form-control  ${
                    formik.touched.first_name && formik.errors.first_name
                      ? "is-invalid"
                      : formik.values.first_name && !formik.errors.first_name
                      ? "is-valid"
                      : ""
                  }`}
                  name="first_name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.first_name}
                  placeholder="أدخل اسم لا يحتوي علي أرقام أو رموز"
                  autoComplete="off"
                />
                <div className="text-muted fs-7 mt-2"></div>
                {formik.errors.first_name && formik.touched.first_name ? (
                  <div className="text-danger mt-2">
                    {formik.errors.first_name}
                  </div>
                ) : null}
              </div>
            </Col>
            <Col md={4}>
              <div className="mb-10 ">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="required form-label"
                >
                  الاسم الأخير
                </label>
                <input
                  type="text"
                  className={`form-control  ${
                    formik.touched.last_name && formik.errors.last_name
                      ? "is-invalid"
                      : formik.values.last_name && !formik.errors.last_name
                      ? "is-valid"
                      : ""
                  }`}
                  name="last_name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.last_name}
                  placeholder="أدخل اسم لا يحتوي علي أرقام أو رموز"
                  autoComplete="off"
                />
                <div className="text-muted fs-7 mt-2"></div>
                {formik.errors.last_name && formik.touched.last_name ? (
                  <div className="text-danger mt-2">
                    {formik.errors.last_name}
                  </div>
                ) : null}
              </div>
            </Col>
            <Col md={4}>
              <div className="mb-10 ">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="required form-label"
                >
                  البريد الالكتروني
                </label>
                <input
                  type="email"
                  className={`form-control ${
                    formik.touched.email && formik.errors.email
                      ? "is-invalid"
                      : formik.values.email && !formik.errors.email
                      ? "is-valid"
                      : ""
                  }`}
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  placeholder="example@domain.com"
                  autoComplete="off"
                />

                {formik.errors.email && formik.touched.email ? (
                  <div className="text-danger mt-2">{formik.errors.email}</div>
                ) : null}
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-10 py-2">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="required form-label"
                >
                  كلمة المرور
                </label>
                <input
                  type="password"
                  className={`form-control  ${
                    formik.touched.password && formik.errors.password
                      ? "is-invalid"
                      : formik.values.password && !formik.errors.password
                      ? "is-valid"
                      : ""
                  }`}
                  name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  placeholder="كلمة المرور : أكثر من 8 أحرف محتوية على رموز، أحرف كبيرة، أحرف صغيرة"
                  autoComplete="off"
                />
                <div className="text-muted fs-7 mt-2"></div>

                {formik.errors.password && formik.touched.password ? (
                  <div className="text-danger mt-2">
                    {formik.errors.password}
                  </div>
                ) : null}
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-10 py-2">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="required form-label"
                >
                  اعد كلمة المرور
                </label>
                <input
                  type="password"
                  className={`form-control  ${
                    formik.touched.password_confirmation &&
                    formik.errors.password_confirmation
                      ? "is-invalid"
                      : formik.values.password_confirmation &&
                        !formik.errors.password_confirmation
                      ? "is-valid"
                      : ""
                  }`}
                  name="password_confirmation"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password_confirmation}
                  placeholder="أعد إدخال كلمة المرور"
                  autoComplete="off"
                />

                {formik.errors.password_confirmation &&
                formik.touched.password_confirmation ? (
                  <div className="text-danger mt-2">
                    {formik.errors.password_confirmation}
                  </div>
                ) : null}
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-10">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="required form-label"
                >
                  اختر الشركة
                </label>
                <select
                  className={`form-control  ${
                    formik.touched.company && formik.errors.company
                      ? "is-invalid"
                      : formik.values.company && !formik.errors.company
                      ? "is-valid"
                      : ""
                  }`}
                  name="company"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.company}
                  aria-label="Select example"
                >
                  <option value="" disabled>
                    اختر الشركة
                  </option>
                  <option value="rt">Rt</option>
                  <option value="eqlaa"> Eqlaa</option>
                </select>
                {formik.errors.company && formik.touched.company ? (
                  <div className="text-danger mt-2">
                    {formik.errors.company}
                  </div>
                ) : null}
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-10">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="required form-label"
                >
                  اختر الدور الوظيفي
                </label>
                <select
                  className={`form-control  ${
                    formik.touched.role && formik.errors.role
                      ? "is-invalid"
                      : formik.values.role && !formik.errors.role
                      ? "is-valid"
                      : ""
                  }`}
                  name="role"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.role}
                  aria-label="Select example"
                >
                  <option value="" disabled>
                    اختر الدور الوظيفي
                  </option>
                  <option value="admin">Admin</option>
                  <option value="agent">Agent</option>
                </select>
                {formik.errors.role && formik.touched.role ? (
                  <div className="text-danger mt-2">{formik.errors.role}</div>
                ) : null}
              </div>
            </Col>

            <Col sm={2}>
              <div className="py-4">
                <button
                  type="submit"
                  className="btn btn-warning w-100 "
                  // disabled={loading || !(formik.isValid && formik.dirty)}
                >
                  {/* {loading ? (
              <span className="spinner-border spinner-border-sm" />
            ) : (
              <>اضف</>
            )} */}
                  <>اضف</>
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Form>
  );
};

export default AddUserForm;
